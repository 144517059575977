<template></template>


<script>
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { db, firebaseApp } from "../store/firebaseDb";

Vue.use(VueCryptojs);
Vue.use(VueCookies);

export default {
  data() {
    return {
      user: {},
      Users: [],
      user_cloud_config: {},
    };
  },

  methods: {
    confirmDeleteRecord() {},

    getUserCloudManagement(cb) {
      const customerName = $cookies.get("customer");
      console.log("customerName=", customerName);

      db.collection("users")
        .where("customer_name", "==", customerName + "")
        .get()
        .then((custInfo) => {
          custInfo.forEach((doc) => {

            const user_cloud_config = doc.data();
            console.log("user_cloud_config1=", user_cloud_config);

            Vue.$cookies.set("user_cloud_config", user_cloud_config);
            this.user_cloud_config = $cookies.get("user_cloud_config");
            console.log("user_cloud_config2=", this.user_cloud_config);

            if (cb) cb();
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onUpdateForm(event) {
      event.preventDefault();
      db.collection("users")
        .doc(this.$route.params.id)
        .update(this.user)
        .then(() => {
          console.log("User successfully updated!");
          this.$router.push("/list");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getHouseInfo() {
      const customerName = $cookies.get("customer");
      const hNumber = $cookies.get("home");

      db.collection("customer/" + customerName + "/house/")
        .doc(hNumber)
        .get()
        .then((houseInfo) => {
          console.log("houseInfo=", houseInfo.data());

          Vue.$cookies.set("houseinfo", houseInfo.data());
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getHouseInfoByUID(uid) {
      const customerName = $cookies.get("customer");
      // const hNumber = $cookies.get("home");

      const docRef = db.collection("customer/" + customerName + "/house/")
        .where("uid", "==", uid);

       const customerRef = await docRef.get();

      console.log("getHouseInfoByUID customerRef2222 length=" + customerRef.docs.length);

      if ( customerRef.docs.length == 0) {
          console.error('No such document!');
          return null;
      }

      return customerRef.docs[0].data();

    },

    // async handleUid() {
    //   var parameters = this.$route.query;
    //   console.log("parameters._uid=" + parameters._uid);
    //   // console.log("parameters._uid lenght="+parameters._uid.length)
    //   if (parameters._uid != null && parameters._uid.length > 2) {
    //     firebaseApp
    //       .auth()
    //       .signOut()
    //       .then( async () =>  {
    //         firebaseApp
    //     .auth()
    //     .signInWithEmailAndPassword("web@koder3.com", "123456x")
    //     .then(
    //       async (user) =>  {
    //         console.log("user", user);

    //         console.log("parameters._uid2=" + parameters._uid);
    //         const _uid = decodeURIComponent(parameters._uid);

    //         const decryptedText = this.CryptoJS.AES.decrypt(
    //           _uid,
    //           "k23qa"
    //         ).toString(this.CryptoJS.enc.Utf8);
    //         console.log("_uid", _uid);
    //         console.log("decryptedText", decryptedText);

    //         Vue.$cookies.set("customer", decryptedText.split("|")[0]);

    //         var houseData = await this.getHouseInfoByUID(_uid);
    //         Vue.$cookies.set("houseinfo", houseData);

    //         console.log("houseData", houseData);
    //         Vue.$cookies.set("home", houseData.house_no);

    //         var customer = $cookies.get("customer");
    //         var home = $cookies.get("home");
    //         console.log("home", home);

    //         const thisGlo = this;

    //         if (home && customer) {
    //           this.getUserCloudManagement(function () {


    //             var t = (new Date()).getTime();
    //           console.log("user_cloud_config3", thisGlo.user_cloud_config);

    //           var goto = "/home-user-news?t="+t;
    //         console.log("goto="+goto);



    //             if (
    //               thisGlo.user_cloud_config != null &&
    //               thisGlo.user_cloud_config.enable_news
    //             ) {

    //               window.location.href = goto;

    //             } else {
    //               window.location.href = goto;
    //             }
    //           });

    //           // this.$router.push("/home-user-news");
    //         } else {
    //           this.$router.push("/pages/error-404");
    //         }

    //         Vue.$cookies.remove("userInfo");
    //         // this.getHouseInfo();
    //       },
    //       (err) => {
    //         alert(err.message);
    //       this.$router.push("/pages/login").catch((error) => {
    //         if (error.name != "NavigationDuplicated") {
    //           throw error;
    //         }
    //       });

    //       }
    //     );


    //       });
    //   } else {
    //     //นิติบุคคลใช้

    //     const customerName = $cookies.get("customer");

    //     if (customerName == "" || customerName == null) {
    //       this.$router.push("/pages/login").catch((error) => {
    //         if (error.name != "NavigationDuplicated") {
    //           throw error;
    //         }
    //       });
    //       return;
    //     }

    //     firebaseApp.auth().onAuthStateChanged((user) => {
    //       console.log("onAuthStateChanged user", user);
    //       if (!user) {
    //         this.$router.push("/pages/login").catch((error) => {
    //           if (error.name != "NavigationDuplicated") {
    //             throw error;
    //           }
    //         });

    //         // this.$router.replace("/pages/login")
    //         //alert("You don't have a permission")
    //       } else {
    //         const thisGlo = this;

    //         this.getUserCloudManagement(function () {
    //                     console.log("onAuthStateChanged user", user);
    //           console.log("user_cloud_config4", thisGlo.user_cloud_config);

    //           if (
    //             thisGlo.user_cloud_config != null &&
    //             thisGlo.user_cloud_config.enable_news
    //           ) {
    //             console.log("goto manage_dashboard");
    //             thisGlo.$router.push("/manage_dashboard").catch((error) => {
    //               if (error.name != "NavigationDuplicated") {
    //                 throw error;
    //               }
    //             });
    //           } else {

    //             console.log("goto manage_transactions");

    //             thisGlo.$router.push("/manage_transactions").catch((error) => {
    //               if (error.name != "NavigationDuplicated") {
    //                 throw error;
    //               }
    //             });
    //           }
    //         });
    //       }
    //     });
    //   }
    // },
  },
  components: {},
  created() {
    // this.handleUid();

    const url_string = window.location.href; // www.test.com?filename=test
    const url = new URL(url_string);
    const uid = url.searchParams.get("_uid");

    // alert("uid="+uid);

    if (uid !== null && uid.length > 2) {
      return;
    }


        const customerName = $cookies.get("customer");

        if (customerName == "" || customerName == null) {
          this.$router.push("/pages/login").catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
          return;
        }

        firebaseApp.auth().onAuthStateChanged((user) => {
          console.log("onAuthStateChanged user", user);
          if (!user) {
            this.$router.push("/pages/login").catch((error) => {
              if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });

          } else {
            const thisGlo = this;

            this.getUserCloudManagement(function () {
                        console.log("onAuthStateChanged user", user);
              console.log("user_cloud_config4", thisGlo.user_cloud_config);

              if (
                thisGlo.user_cloud_config != null &&
                thisGlo.user_cloud_config.enable_news
              ) {
                console.log("goto manage_dashboard");
                thisGlo.$router.push("/manage_dashboard").catch((error) => {
                  if (error.name != "NavigationDuplicated") {
                    throw error;
                  }
                });
              } else {

                console.log("goto manage_transactions");

                thisGlo.$router.push("/manage_transactions").catch((error) => {
                  if (error.name != "NavigationDuplicated") {
                    throw error;
                  }
                });
              }
            });
          }
        });
  },
};
</script>
